import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ResponsiveEmbed from "react-responsive-embed"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <div className="page-container">
      <div className="content-container">
        <Layout location={location} title={siteTitle}>
          <SEO title="404: Page Not Found" />

          <h1 className="txt-primary">404: Not Found</h1>
          <h3 className="txt-secondary">
            Our Church doesn&#39;t have a page for this URL... the sadness.
          </h3>
          <div className="video-padding ">
            <ResponsiveEmbed src="https://giphy.com/embed/l1J9qemh1La8b0Rag" />
          </div>
        </Layout>
      </div>
    </div>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
